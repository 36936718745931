import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_ALL_TRIALS_APPROVALS_REQUEST',
  'GET_ALL_TRIALS_APPROVALS_SUCCESS',
  'GET_ALL_TRIALS_APPROVALS_ERROR',
  'GET_MY_TRIALS_APPROVALS_REQUEST',
  'GET_MY_TRIALS_APPROVALS_SUCCESS',
  'GET_MY_TRIALS_APPROVALS_ERROR',
  'GET_ALL_TRIALS_SUBMITTED_APPROVALS_REQUEST',
  'GET_ALL_TRIALS_SUBMITTED_APPROVALS_SUCCESS',
  'GET_ALL_TRIALS_SUBMITTED_APPROVALS_ERROR',
  'GET_MY_TRIALS_SUBMITTED_APPROVALS_REQUEST',
  'GET_MY_TRIALS_SUBMITTED_APPROVALS_SUCCESS',
  'GET_MY_TRIALS_SUBMITTED_APPROVALS_ERROR',
  'GET_PARTICIPANT_WAITING_APPROVALS_REQUEST',
  'GET_PARTICIPANT_WAITING_APPROVALS_SUCCESS',
  'GET_PARTICIPANT_WAITING_APPROVALS_ERROR',
  'VIEW_TRIALS_SUBMITTED_REQUEST',
  'VIEW_TRIALS_SUBMITTED_SUCCESS',
  'VIEW_TRIALS_SUBMITTED_ERROR',
  'APPROVE_TRIALS_REQUEST',
  'APPROVE_TRIALS_SUCCESS',
  'APPROVE_TRIALS_ERROR',
  'SUBMIT_TRIALS_REQUEST',
  'SUBMIT_TRIALS_SUCCESS',
  'SUBMIT_TRIALS_ERROR'
);
