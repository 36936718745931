import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { Formik } from 'formik';
import Modal from 'components/Modal';
import Button from 'components/Button';
import ParticipantInfo from './components/ParticipantInfo';
import Progress from './components/Progress';
import Guidelines from './components/Guidelines';
import Description from './components/Description';
import {
  getSchema,
  getInitChecked,
  isAllChecked,
  getNextParticipantId,
  getProgressPercent,
  getIsLastParticipant,
} from './utils';
import classes from './ParticipantGuidelinesModal.module.scss';

const ParticipantGuidelinesModal = ({
  className,
  isOpen,
  onClose,
  onFinish,
  data,
}) => {
  const isExternalData = !isEmpty(data);
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const startTimerLoading = useSelector(({ timer }) =>
    get(timer, 'startTimer.loading', false)
  );
  const reviewGuidelinesLoading = useSelector(({ guideline }) =>
    get(guideline, 'reviewGuidelines.loading', false)
  );
  const storeParticipants = useSelector(
    ({ guideline }) => get(guideline, 'locationGuidelines.data') || []
  );
  const participants = isExternalData ? data.participants : storeParticipants;
  const listRef = useRef(null);
  const formRef = useRef(null);
  const [activeParticipantId, setActiveParticipantId] = useState('');
  const [guidelineChecked, setGuidelineChecked] = useState({});
  const currentParticipantIndex =
    findIndex(participants, { id: activeParticipantId }) || 0;
  const currentParticipantChecked = guidelineChecked[activeParticipantId];
  const isAllGuidelinesChecked = isAllChecked(currentParticipantChecked);
  const foundParticipant =
    find(participants, { id: activeParticipantId }) || {};
  const isLast = getIsLastParticipant(participants, activeParticipantId);
  const handleCheck = id => isChecked => {
    const oldPartChecked = guidelineChecked[activeParticipantId] || {};
    const newPartChecked = {
      ...oldPartChecked,
      [id]: isChecked,
    };
    setGuidelineChecked({
      ...guidelineChecked,
      [activeParticipantId]: newPartChecked,
    });
  };
  useEffect(() => {
    if (isOpen) {
      setActiveParticipantId(participants[0]?.id);
      setGuidelineChecked(getInitChecked(participants));
    }
  }, [isOpen, participants]);

  const renderForm = formProps => {
    const { handleSubmit } = formProps;
    return (
      <form onSubmit={handleSubmit} className={classes.form}>
        <div className={classes.formInner}>
          <div className={classes.formHeader}>
            <ParticipantInfo
              className={classes.participantInfo}
              currentParticipant={
                find(participants, { id: activeParticipantId }) || {}
              }
            />
            <Progress
              percent={getProgressPercent(participants, activeParticipantId)}
              participantNumber={currentParticipantIndex + 1}
              participantsLength={participants.length}
              className={classes.progress}
            />
          </div>
          <Guidelines
            participants={participants}
            activeParticipantId={activeParticipantId}
            setActiveParticipantId={setActiveParticipantId}
            guidelines={foundParticipant.guidelines || []}
            onCheck={handleCheck}
            guidelineChecked={guidelineChecked[activeParticipantId]}
            listRef={listRef}
            userTimeZone={userTimeZone}
          />
          <Description />
        </div>
        <div className={classes.footerButtons}>
          <Button
            className={classes.cancelBtn}
            htmlType="button"
            onClick={onClose}
            type="default"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="button"
            isDisabled={!isAllGuidelinesChecked}
            onClick={() => {
              if (isLast) {
                onFinish(participants);
              } else {
                setActiveParticipantId(
                  getNextParticipantId(participants, activeParticipantId)
                );
                if (listRef && listRef.current) {
                  listRef.current.scrollTo(0, 0);
                }
              }
            }}
            loading={
              !isExternalData
                ? startTimerLoading || reviewGuidelinesLoading
                : data.loading
            }
          >
            {isLast
              ? `Finish${isExternalData ? '' : ' Review'}`
              : `${isExternalData ? '' : 'Review '}Next`}
          </Button>
        </div>
      </form>
    );
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      closable
      title={'Participant Guidelines'}
      size="large"
      width={'492px'}
      wrapClassName={cn(classes.modalWrapper, className)}
    >
      <div className={classes.modalContent}>
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={getSchema()}
          onSubmit={() => {}}
          innerRef={formRef}
        >
          {renderForm}
        </Formik>
      </div>
    </Modal>
  );
};

export default ParticipantGuidelinesModal;
