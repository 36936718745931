import { store } from 'store';
import get from 'lodash/get';
import { useEffect } from 'react';
import history from 'helpers/history';

export const roles = {
  COMPANY_ADMIN: ['Company_Administrator'],
  BASIC_TEAM_MEMBER: 'Basic_Team_Member',
};

export const permissions = {
  CREATE_USERS: 'create users',
  DELETE_USERS: 'delete users',
  EDIT_ALL_USER_PROFILES: 'edit all user profiles',
  EDIT_OWN_PROFILE: 'edit own profile',
  CREATE_PARTICIPANTS: 'create participants',
  EDIT_ALL_PARTICIPANTS: 'edit all participants',
  EDIT_ASSIGNED_PARTICIPANTS: 'edit assigned participants',
  VIEW_ALL_PARTICIPANTS: 'view all participants',
  VIEW_ASSIGNED_PARTICIPANTS: 'view assigned participants',
  DELETE_ALL_PARTICIPANTS: 'delete all participants',
  DELETE_ASSIGNED_PARTICIPANTS: 'delete assigned participants',
  EDIT_ROLLSHEET: 'edit rollsheet',
  LIST_SERVICES: 'list services',
  CREATE_SERVICE: 'create services',
  EDIT_ALL_SERVICES: 'edit all services',
  MANAGE_ISPS_FOR_ALL_PARTICIPANTS: 'manage isps for all participants',
  VIEW_CSRS: 'view csrs',
  MANAGE_ISPS_FOR_ASSIGNED_PARTICIPANTS:
    'manage isps for assigned participants',
  APPROVE_CSRS: 'approve csrs',
  UPDATE_CSRS: 'update csrs',
  LIST_LOCATIONS: 'list locations',
  CREATE_LOCATIONS: 'create locations',
  EDIT_LOCATIONS: 'edit locations',
  DELETE_LOCATIONS: 'delete locations',
  UPDATE_LOCATION_FOR_ALL_PARTICIPANTS: 'update location for all participants',
  UPDATE_LOCATION_FOR_ASSIGNED_PARTICIPANTS:
    'update location for assigned participants',
  EXPAND_ALL_CSRS: 'expand all csrs',
  VIEW_TOTAL_BILLED_AMOUNT: 'view total billed amount',
  DOWNLOAD_CSRS: 'download csrs',
  PRINT_CSRS: 'print csrs',
  SUBMIT_CSRS: 'submit csrs',
  VIEW_ALL_APARTMENTS_OPTION: 'view all apartments option on schedules',
  VIEW_WAITING_SUBMISSION_CSRS: 'view waiting submission csrs',
  VIEW_ALL_USER_SHIFTS: 'view all user shifts',
  VIEW_ASSIGNED_USERS_SHIFTS: 'view assigned users shifts',
  USE_TIMER: 'use timer',
  CREATE_EMPLOYEE_TIME_ENTRY: 'create manual employee time entry',
  EDIT_EMPLOYEE_TIME_ENTRY: 'edit employee time entry',
  DELETE_EMPLOYEE_TIME_ENTRY: 'delete employee time entry',
  VIEW_PAYROLL_PAGE: 'view payroll page',
  VIEW_ANNOUNCEMENTS_PAGE: 'access announcements',
  SUBMIT_TIME_ENTRY_EDIT_REQUEST: 'submit time entry edit request',
  VIEW_ROSTER_PAGE: 'view employee roster page',
  EDIT_EMPLOYEE_NA_TIME_ENTRY: 'edit night assist time entry',
  DELETE_EMPLOYEE_NA_TIME_ENTRY: 'delete night assist time entry',
  APPROVE_TIME_EDIT_REQUESTS: 'approve time edit requests',
  APPROVE_REIMBURSEMENT_REQUESTS: 'approve reimbursement requests',
  APPROVE_MILEAGE_REQUESTS: 'approve mileage requests',
  APPROVE_NIGHT_ASSIST_REQUESTS: 'approve night assist requests',
  APPROVE_FILL_SHIFT_REQUESTS: 'approve fill shift requests',
  APPROVE_TIME_OFF_REQUESTS: 'approve time off requests',
  APPROVE_SWAP_SHIFTS_REQUESTS: 'approve swap shifts requests',
  MODIFY_PAST_SHIFTS: 'modify_past_shifts',
  ADD_AVAILABILITY: 'add availability',
  ADD_TEMPORARY_DDA: 'add temporary dda shifts',
  VIEW_BONOUS_SETTINGS: 'access bonus settings',
  VIEW_REIMBURSEMENT_SETTINGS: 'access reimbursement settings',
  CREATE_BONUS: 'create bonus',
  EDIT_BONUS: 'edit bonus',
  DELETE_BONUS: 'delete bonus',
  EDIT_REIMBURSEMENT: 'edit reimbursement',
  DELETE_ALL_REIMBURSEMENT: 'delete all reimbursement',
  DELETE_OWN_REIMBURSEMENT: 'delete own reimbursement',
  ACCESS_QB_INTEGRATION: 'access qb integration',
  ACCESS_ADMINISTRATION: 'access administration',
  ACCESS_SCHEDULES: 'access schedules',
  ACTIVATE_PIP: 'activate pip',
  RUN_PIP_TRIALS: 'run pip trials',
  TRIALS_APPROVALS: 'approve pip trials',
};

export const isAdmin = () => {
  const storeData = store.getState();
  const currentRoles = get(storeData, 'auth.user.roles', []) || [];

  const rs = currentRoles.some(role => {
    return roles.COMPANY_ADMIN.indexOf(role) >= 0 ? true : false;
  });

  return rs ? true : false;
};

export const canAccess = (permissions, eitherOr = true) => {
  const storeData = store.getState();
  const currentPermissions = get(storeData, 'auth.user.permissions', []) || [];
  const finalPermissions = [].concat(permissions);
  if (eitherOr) {
    // if eitherOr = true that means at least one permission should be satisfied
    return finalPermissions.some(perm => currentPermissions.includes(perm));
  } else {
    // if eitherOr = false that means all permission should be satisfied
    return finalPermissions.every(perm => currentPermissions.includes(perm));
  }
};

export const useUnauthorized = (...args) => {
  const isAllowedPermissions = canAccess(...args);
  useEffect(() => {
    if (!isAllowedPermissions) history.push('/');
  }, [isAllowedPermissions]);
};
