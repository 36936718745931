import React, { useState } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import cs from 'classnames';
import classes from './Sidebar.module.scss';
import { useLocation } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useModal } from 'components/Modal';
import { setClockModal } from 'store/actions/timer';
import { ReactComponent as InventorySvg } from 'assets/icons/inventory.svg';
import history from 'helpers/history';
import { canAccess, permissions } from 'helpers/permission';
import { handleOpenWarningModal } from 'views/pages/Payroll/utils';
import Logo from 'components/Logo';
import { EMPLOYEE_APPROVALS_PERMISSIONS } from 'constants/role';
import { EA_COUNT_KEY_PERMISSION_MAPPING } from 'constants/employeeApprovals';
import BadgeCount from '../../components/BadgeCount';
import Dot from './Dot';
import { ActionButton } from '../TimerButton/components/Elements';

const SidebarContent = ({
  isDrawer,
  onToggleCollapsedNav,
  toggleHover,
  isHover,
  isStay,
}) => {
  const [modal, contextHolder] = useModal();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAllowedViewApprovals = canAccess(permissions.VIEW_CSRS);
  const isAllowedViewLocations = canAccess(permissions.LIST_LOCATIONS);
  const isAllowedViewServices = canAccess(permissions.LIST_SERVICES);
  const isViewPayroll = canAccess(permissions.VIEW_PAYROLL_PAGE);
  const isViewAnnouncements = canAccess(permissions.VIEW_ANNOUNCEMENTS_PAGE);
  const isViewRoster = canAccess(permissions.VIEW_ROSTER_PAGE);
  const isViewEmployeeApprovals = canAccess(EMPLOYEE_APPROVALS_PERMISSIONS);
  const isViewBonous = canAccess(permissions.VIEW_BONOUS_SETTINGS);
  const isViewReimbursement = canAccess(
    permissions.VIEW_REIMBURSEMENT_SETTINGS
  );
  const isViewBonusReimbursement = isViewBonous || isViewReimbursement;
  const isAccessQBIntegration = canAccess(permissions.ACCESS_QB_INTEGRATION);
  const isAccessAdministration = canAccess(permissions.ACCESS_ADMINISTRATION);
  const isAccessSchedules = canAccess(permissions.ACCESS_SCHEDULES);
  const isRunPIPTrials = canAccess(permissions.RUN_PIP_TRIALS);
  const isTrialsApprovals = canAccess(permissions.TRIALS_APPROVALS);
  const user = useSelector(({ auth }) => auth.user);
  const newPIPChanged = useSelector(
    ({ userSettings }) => userSettings?.participantSettings?.newPIPChanged
  );
  const [openKeys, setOpenKeys] = useState([]);
  const csrSummary = useSelector(
    ({ csrSummary }) => get(csrSummary, 'csrSummary.data') || {}
  );
  const employeeApprovalBadges = useSelector(
    ({ employeeApproval }) => get(employeeApproval, 'badges') || {}
  );
  let employeeApprovalBadgesTotal = 0;
  Object.keys(EA_COUNT_KEY_PERMISSION_MAPPING)
    .filter(key => !!canAccess(EA_COUNT_KEY_PERMISSION_MAPPING[key]))
    .forEach(key => {
      employeeApprovalBadgesTotal =
        employeeApprovalBadgesTotal + employeeApprovalBadges[key];
    });
  const myCaseCount = csrSummary.my_case_load || 0;
  const allCount = csrSummary.all || 0;
  const myPendingCount = csrSummary.my_pending_auth || 0;
  // TODO: quickbooks badge
  const quickbooksBadge = false;
  const MyCaseBadge = () => (
    <BadgeCount
      className={cs('gx-ml-3', classes.myCaseLoad)}
      count={myCaseCount}
      showZero={false}
    />
  );
  const AllBadge = () => (
    <BadgeCount
      className={cs('gx-ml-3', classes.all)}
      count={allCount}
      showZero={false}
    />
  );
  const EmployeeApprovalsBadge = () => (
    <BadgeCount
      className={cs('gx-ml-3', classes.myCaseLoad)}
      count={employeeApprovalBadgesTotal}
      showZero={false}
    />
  );
  const TrialsApprovalsBadge = () => (
    <BadgeCount
      className={cs('gx-ml-3', classes.myCaseLoad)}
      count={user?.my_trial_approvals || 0}
      showZero={false}
    />
  );
  const QuickbooksBadge = () => (
    <BadgeCount
      className={cs('gx-ml-1', classes.quickbooksBadge)}
      count="!"
      showZero={false}
    />
  );
  const sidebarItems = [
    {
      label: 'Dashboard',
      path: '/dashboard',
      icon: <span className="material-icons">dashboard</span>,
    },
    ...(isRunPIPTrials
      ? [
          {
            label: 'Participant Trials',
            path: '/participant-trials',
            icon: <InventorySvg />,
          },
        ]
      : []),
    {
      label: 'Roll Sheet',
      path: '/roll-sheet',
      icon: <span className="material-icons">how_to_reg</span>,
    },
    ...(isAllowedViewApprovals
      ? [
          {
            label: 'Approvals',
            badge: (
              <div className={classes.menuItemBadge}>
                {myCaseCount || allCount ? (
                  openKeys.includes('/approvals') ? null : myCaseCount === 0 ? (
                    <AllBadge />
                  ) : (
                    <MyCaseBadge />
                  )
                ) : myPendingCount ? (
                  <div className={classes.pendingBadge} />
                ) : null}
              </div>
            ),
            path: `/approvals?q=${user.id}`,
            icon: <span className="material-icons">library_add_check</span>,
            routes: [
              {
                label: (
                  <div className="gx-d-flex gx-align-items-center">
                    My Approvals
                    <MyCaseBadge />
                  </div>
                ),
                path: `/approvals?q=${user.id}`,
              },
              {
                label: (
                  <div className="gx-d-flex gx-align-items-center">
                    Pending Auth
                    {myPendingCount ? (
                      <div className={classes.pendingBadge} />
                    ) : null}
                  </div>
                ),
                path: `/pending_auth${myPendingCount ? '' : '?q=all'}`,
              },
              {
                label: (
                  <div className="gx-d-flex gx-align-items-center">
                    All Approvals
                    <AllBadge />
                  </div>
                ),
                path: `/approvals?q=all`,
              },
            ],
          },
        ]
      : []),
    ...(isTrialsApprovals
      ? [
          {
            label: 'Trials Approvals',
            path: `/my-trials-approvals`,
            icon: <span className="material-icons">check</span>,
            routes: [
              {
                label: (
                  <div className="gx-d-flex gx-align-items-center">
                    My Approvals
                    <TrialsApprovalsBadge />
                  </div>
                ),
                path: `/my-trials-approvals`,
              },
              {
                label: (
                  <div className="gx-d-flex gx-align-items-center">
                    All Approvals
                  </div>
                ),
                path: `/all-trials-approvals`,
              },
            ],
          },
        ]
      : []),
    ...(get(user, 'participants', [])
      ? [
          {
            label: 'Participants',
            path: '/participants',
            icon: <span className="material-icons">account_box</span>,
          },
        ]
      : []),
    ...(isViewEmployeeApprovals
      ? [
          {
            label: (
              <div className="gx-d-flex gx-align-items-center">
                Employee Approvals
                <EmployeeApprovalsBadge />
              </div>
            ),
            path: `/employee-approvals`,
            icon: <span className="material-icons">task</span>,
          },
        ]
      : []),
    ...(isViewRoster
      ? [
          {
            label: (
              <div className="gx-d-flex gx-align-items-center">Roster</div>
            ),
            path: `/roster`,
            icon: <span className="material-icons">assignment</span>,
          },
        ]
      : []),
    ...(isAccessAdministration
      ? [
          {
            label: 'Administration',
            path: `/employees`,
            icon: <span className="material-icons">group</span>,
            routes: [
              ...[
                {
                  label: (
                    <div className="gx-d-flex gx-align-items-center">
                      Employees
                    </div>
                  ),
                  path: `/employees`,
                },
              ],
              ...(isViewPayroll
                ? [
                    {
                      label: (
                        <div className="gx-d-flex gx-align-items-center">
                          Payroll
                        </div>
                      ),
                      path: `/payroll`,
                    },
                  ]
                : []),
              ...(isViewAnnouncements
                ? [
                    {
                      label: (
                        <div className="gx-d-flex gx-align-items-center">
                          Announcements
                        </div>
                      ),
                      path: `/announcements`,
                    },
                  ]
                : []),
              ...(isViewBonusReimbursement
                ? [
                    {
                      label: (
                        <div className="gx-d-flex gx-align-items-center">
                          Bonus & Reimbursements
                        </div>
                      ),
                      path: `/bonuses-reimbursements`,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isAccessSchedules
      ? [
          {
            label: 'Schedules',
            path: `/office-calendar`,
            icon: <span className="material-icons">date_range</span>,
            routes: [
              {
                label: (
                  <div className="gx-d-flex gx-align-items-center">
                    Office Calendar
                  </div>
                ),
                path: `/office-calendar`,
              },
              {
                label: (
                  <div className="gx-d-flex gx-align-items-center">
                    ResHab Schedules
                  </div>
                ),
                path: `/schedules`,
              },
              {
                label: (
                  <div className="gx-d-flex gx-align-items-center">
                    DDA Schedules
                  </div>
                ),
                path: `/dda-schedules`,
              },
            ],
          },
        ]
      : []),
    ...(isAllowedViewServices || isAllowedViewLocations
      ? [
          {
            label: (
              <>
                Settings
                {quickbooksBadge && <Dot top />}
              </>
            ),
            path: `/services`,
            icon: <span className="material-icons">settings</span>,
            routes: [
              ...(isAllowedViewServices
                ? [
                    {
                      label: (
                        <div className="gx-d-flex gx-align-items-center">
                          Services
                        </div>
                      ),
                      path: `/services`,
                    },
                  ]
                : []),
              ...(isAllowedViewLocations
                ? [
                    {
                      label: (
                        <div className="gx-d-flex gx-align-items-center">
                          Locations
                        </div>
                      ),
                      path: `/locations`,
                    },
                  ]
                : []),
              ...(isAccessQBIntegration
                ? [
                    {
                      label: (
                        <div className="gx-d-flex gx-align-items-center">
                          Quickbooks Integration
                          {quickbooksBadge && <QuickbooksBadge />}
                        </div>
                      ),
                      path: `/quickbooks-integration`,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];
  const isHomePage = location.pathname === '/';
  const selectedKeys = !isHomePage
    ? sidebarItems.find(item => location.pathname.includes(item.path))
    : sidebarItems[0];

  const handleSubMenuOpenClose = keys => {
    setOpenKeys(keys);
  };
  const warningModalFn = cb => {
    if (!newPIPChanged) {
      if (cb) cb();
    } else {
      handleOpenWarningModal(modal)(
        <>
          Are you sure you want to exit creating this PIP? Any progress you’ve
          made will be lost.
          <br />
          <br />
          If needed, you can save the PIP as a draft to continue editing later.
        </>,
        true,
        () => {},
        () => {
          if (cb) cb();
        },
        'Exit Without Saving',
        {
          okText: 'Continue Editing',
          className: classes.warningModal,
          cancelText: 'Leave Page',
        }
      );
    }
  };
  const renderMenuItems = () => {
    return sidebarItems.map(({ path, icon, label, routes, badge }) =>
      routes && routes.length ? (
        <Menu.SubMenu
          id={path}
          key={path}
          className={cs(classes.menuItem, 'gx-d-flex gx-flex-column')}
          title={
            <div className="gx-d-flex gx-w-100 gx-justify-content-between">
              <div className="gx-d-flex gx-w-100">
                <a
                  href="#/"
                  onClick={e => {
                    warningModalFn(() => {
                      history.push(path);
                    });
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="gx-d-flex"
                >
                  <span className={cs('gx-mr-3', classes.inactive)}>
                    {icon ? icon : null}
                  </span>
                </a>
                <span className={cs(classes.inactive)}>
                  <div className="gx-d-flex gx-align-items-center">
                    {label}
                    {badge}
                  </div>
                </span>
              </div>
              <span className={cs('material-icons', classes.chevron)}>
                {!openKeys.includes(path) ? 'expand_more' : 'expand_less'}
              </span>
            </div>
          }
        >
          {routes.map(subRoute => {
            let isActive = isHomePage
              ? false
              : subRoute.path.startsWith(location.pathname);
            const hasSearch = subRoute.path.includes('?q=');
            if (hasSearch && subRoute.path.includes('?q=all')) {
              isActive = isActive && location.search === '?q=all';
            } else if (hasSearch && !subRoute.path.includes('?q=all')) {
              isActive =
                isActive &&
                location.search.includes('?q=') &&
                location.search !== '?q=all';
            }
            return (
              <Menu.Item
                key={subRoute.path}
                title={subRoute.label}
                className={cs(classes.menuItemWrapper, {
                  [classes.isActive]: isActive,
                })}
              >
                <a
                  href="#/"
                  className={cs(
                    classes.menuItem,
                    classes.subMenu,
                    classes.addMargin
                  )}
                  onClick={e => {
                    warningModalFn(() => {
                      history.push(subRoute.path);
                    });
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <span
                    className={cs('gx-mr-3', classes.inactive, {
                      [classes.active]: isActive,
                    })}
                  >
                    {subRoute.icon ? subRoute.icon : null}
                  </span>
                  <span
                    className={cs(classes.inactive, {
                      [classes.active]: isActive,
                    })}
                  >
                    {subRoute.label}
                  </span>
                </a>
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      ) : (
        <Menu.Item
          key={path}
          title={label}
          className={cs(classes.menuItemWrapper, {
            [classes.isActive]: isHomePage
              ? path === '/dashboard'
              : location.pathname.startsWith(path),
          })}
        >
          <a
            href="#/"
            className={cs(classes.menuItem, classes.addMargin)}
            onClick={e => {
              warningModalFn(() => {
                history.push(path);
                if (isDrawer) {
                  onToggleCollapsedNav();
                }
              });
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span
              className={cs('gx-mr-3', classes.inactive, {
                [classes.active]: isHomePage
                  ? path === '/dashboard'
                  : location.pathname.startsWith(path),
              })}
            >
              {icon ? icon : null}
            </span>
            <span
              className={cs(classes.inactive, {
                [classes.active]: isHomePage
                  ? path === '/dashboard'
                  : location.pathname.startsWith(path),
              })}
            >
              {label}
            </span>
          </a>
        </Menu.Item>
      )
    );
  };
  const isStopButton = useSelector(({ timer }) =>
    get(timer, 'isStarting', false)
  );
  const hasTimerButton =
    isDrawer && !isStopButton && canAccess(permissions.USE_TIMER);

  return (
    <>
      <div className="gx-layout-sider-header gx-bg-white">
        <div className="gx-linebar">
          <i
            className={`icon icon-${
              isHover || isStay ? 'menu-unfold' : 'menu-fold'
            } ${classes.menuButton}`}
            onClick={toggleHover}
          />
        </div>
        <Link to="/" className="gx-site-logo">
          <Logo />
        </Link>
      </div>
      <div className={`gx-sidebar-content gx-mt-${hasTimerButton ? '2' : '4'}`}>
        <CustomScrollbars
          className={cs('gx-layout-sider-scrollbar', classes.customScroll)}
        >
          <Menu
            openKeys={openKeys}
            selectedKeys={[selectedKeys]}
            theme="dark"
            mode="inline"
            onOpenChange={handleSubMenuOpenClose}
          >
            {hasTimerButton && (
              <div className={classes.timerButton}>
                <div>
                  <ActionButton
                    onClick={() => {
                      dispatch(
                        setClockModal('clockModal', {
                          data: {},
                          isOpen: true,
                        })
                      );
                    }}
                  />
                </div>
              </div>
            )}
            {renderMenuItems()}
          </Menu>
        </CustomScrollbars>
      </div>
      {contextHolder}
    </>
  );
};

export default SidebarContent;
