import React from 'react';
import cn from 'classnames';
import { Button as AntdButton } from 'antd';
import classes from './Button.module.scss';

const Button = props => {
  const {
    htmlType,
    buttonType,
    size,
    shape,
    target,
    icon,
    isLoading,
    isDisabled,
    isFullWidth,
    isDanger,
    isGhost,
    onClick,
    className,
    noMargin,
    weight,
    flex,
    fontSize,
    ...rest
  } = props;

  return (
    <AntdButton
      type={buttonType}
      htmlType={htmlType}
      size={size}
      shape={shape}
      target={target}
      icon={icon}
      loading={isLoading}
      disabled={isDisabled}
      block={isFullWidth}
      danger={isDanger}
      ghost={isGhost}
      onClick={onClick}
      {...rest}
      className={cn(
        classes.wrapper,
        {
          [classes.noMargin]: noMargin,
          [classes.flex]: !!flex,
        },
        classes[`weight_${weight}`],
        classes[`fontSize_${fontSize}`],
        className
      )}
    >
      {props.children}
    </AntdButton>
  );
};

Button.defaultProps = {
  htmlType: 'button',
  buttonType: 'primary',
  size: 'middle',
  shape: '',
  target: '',
  icon: null,
  isLoading: false,
  isDisabled: false,
  isFullWidth: false,
  isDanger: false,
  isGhost: false,
  onClick: () => {},
};

export default Button;
