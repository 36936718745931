import moment from 'moment';

export const getEndPeriod = start => {
  if (!start) return '';
  return start
    .clone()
    .add(2, 'week')
    .subtract(1, 'day');
};
export const getCurrentStartPeriod = userTimeZone => {
  const startPeriod = moment('2022-06-26').tz(userTimeZone);
  const today = moment().tz(userTimeZone);
  const diff = today.diff(startPeriod, 'week');
  let currentStart = startPeriod.clone();
  for (let i = 2; i <= diff; i++) {
    if (i % 2 === 0) {
      const newCurrentStart = startPeriod.clone().add(i, 'week');
      if (today.isSameOrAfter(newCurrentStart)) {
        currentStart = newCurrentStart.clone();
      }
    }
  }
  return currentStart.clone();
};
