import Request from './request';

export const getParticipants = (
  q,
  page,
  program,
  archived,
  otherFilters = {}
) => {
  const params = {
    q: q,
    page,
    program_id: program ? program : '',
    ...otherFilters,
  };
  if (archived) {
    params['archived'] = true;
  }
  return Request.call({
    url: '/participants',
    method: 'GET',
    params,
  });
};

export const createParticipant = async data => {
  return Request.call({
    url: `/participants`,
    method: 'POST',
    data,
  });
};

export const addManualCSRToParticipant = (participant, csr, data) => {
  return Request.call({
    url: `participants/${participant}/csrs/${csr}`,
    method: 'POST',
    data,
  });
};

export const deleteParticipant = async id => {
  return Request.call({
    url: `/participants/${id}`,
    method: 'DELETE',
  });
};

export const getParticipantById = async id => {
  return Request.call({
    url: `/participants/${id}`,
    method: 'GET',
  });
};

export const getPendingAuthCSRForParticipant = async id => {
  return Request.call({
    url: `/participants/${id}/csrs/missing-auth`,
    method: 'GET',
  });
};

export const updateParticipant = async (id, data) => {
  return Request.call({
    url: `/participants/${id}`,
    method: 'PUT',
    data,
  });
};

export const updateAvatar = async (id, data) => {
  return Request.call({
    url: `/participants/${id}/avatar`,
    method: 'POST',
    data,
  });
};

export const getTimeEntries = async (participant, params) => {
  return Request.call({
    url: `/participants/${participant}/timesheet`,
    method: 'GET',
    params: {
      ...params,
      limit: 20,
    },
  });
};
export const genrateParticipantCsrs = async (id, params) => {
  return Request.call({
    url: `/participants/${id}/csrs/regenerate?date_from=${params?.date_from}&date_to=${params?.date_to}`,
    method: 'POST',
    data: {},
  });
};

export const getIsps = async participant => {
  return Request.call({
    url: `/participants/${participant}/isps`,
    method: 'GET',
  });
};

export const getIspById = async (participant, id) => {
  return Request.call({
    url: `/participants/${participant}/isps/${id}`,
    method: 'GET',
  });
};

export const createIsp = async (participant, data) => {
  return Request.call({
    url: `/participants/${participant}/isps`,
    method: 'POST',
    data,
  });
};

export const updateIsp = async (participant, isp, data) => {
  return Request.call({
    url: `/participants/${participant}/isps/${isp}`,
    method: 'PUT',
    data,
  });
};

export const deleteIsp = async (participant, isp) => {
  return Request.call({
    url: `/participants/${participant}/isps/${isp}`,
    method: 'DELETE',
  });
};

export const getItemsForIsp = async (participant, isp) => {
  return Request.call({
    url: `/participants/${participant}/isps/${isp}`,
    method: 'GET',
  });
};

export const addServiceForIsp = async (isp, data) => {
  return Request.call({
    url: `/isps/${isp}/services`,
    method: 'POST',
    data,
  });
};

export const updateServiceForIsp = async (isp, service, data) => {
  return Request.call({
    url: `/isps/${isp}/services/${service}`,
    method: 'PUT',
    data,
  });
};

export const deleteServiceForIsp = async (isp, service, data) => {
  return Request.call({
    url: `/isps/${isp}/services/${service}`,
    method: 'DELETE',
  });
};

export const prioritizeServiceForIsp = async (isp, data) => {
  return Request.call({
    url: `/isps/${isp}/services/prioritize`,
    method: 'POST',
    data,
  });
};

export const deleteServiceFromCSR = async (participantId, csr, service) => {
  return Request.call({
    url: `/participants/${participantId}/csrs/${csr}/${service}`,
    method: 'DELETE',
  });
};

export const updateServiceRowInCSR = async (
  participantId,
  csr,
  service,
  data
) => {
  return Request.call({
    url: `/participants/${participantId}/csrs/${csr}/${service}`,
    method: 'PUT',
    data,
  });
};

export const deleteCsr = async (participant, csr) => {
  return Request.call({
    url: `/participants/${participant}/csrs/${csr}`,
    method: 'DELETE',
  });
};

export const assignParticipantLocation = async (participant, location) => {
  return Request.call({
    url: `/participants/${participant}/location/${location}`,
    method: 'PUT',
  });
};
export const apiRestoreParticipant = async participantId => {
  return Request.call({
    url: `/participants/${participantId}/restore`,
    method: 'POST',
  });
};
export const apiPrintParticipant = async participantId => {
  return Request.call({
    url: `/participants/${participantId}/print`,
    method: 'POST',
  });
};
export const apiCreateParticipantPIP = async (participantId, data) => {
  return Request.call({
    url: `/participants/${participantId}/pips`,
    method: 'POST',
    data,
  });
};
export const apiGetParticipantPIPs = async (participantId, params) => {
  return Request.call({
    url: `/participants/${participantId}/pips`,
    method: 'GET',
    params,
  });
};
export const apiGetParticipantPIP = async (participantId, pipId) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}`,
    method: 'GET',
    params: {},
  });
};
export const apiUpdateParticipantPIP = async (participantId, pipId, data) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}`,
    method: 'PUT',
    data,
  });
};
export const apiArchiveParticipantPIP = async (participantId, pipId) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}/archive`,
    method: 'PATCH',
    data: {},
  });
};
export const apiActivateParticipantPIP = async (participantId, pipId) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}/activate`,
    method: 'PATCH',
    data: {},
  });
};
export const apiGetParticipantPIPRunning = async participantId => {
  return Request.call({
    url: `/participants/${participantId}/pips/trial/running`,
    method: 'GET',
    params: {},
  });
};
export const apiStartParticipantPIPTrial = async (participantId, pipId) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}/trial/start`,
    method: 'POST',
    data: {},
  });
};
export const apiStopParticipantPIPTrial = async (
  participantId,
  pipId,
  data
) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}/trial/stop`,
    method: 'POST',
    data,
  });
};
export const apiCancelParticipantPIPTrial = async (
  participantId,
  pipId,
  data
) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}/trial/cancel`,
    method: 'POST',
    data,
  });
};
export const apiGetCurrentUserPIPs = async () => {
  return Request.call({
    url: '/me/participants/pips',
    method: 'GET',
  });
};
export const apiSelectParticipantPIPs = async data => {
  return Request.call({
    url: '/me/participants/pips',
    method: 'POST',
    data,
  });
};
export const apiTogglePIPRunTrial = async (participantId, pipId, isON) => {
  const url = `/participants/${participantId}/pips/${pipId}/${
    isON ? 'enable' : 'disable'
  }-trial`;
  return Request.call({
    url,
    method: 'PATCH',
    data: {},
  });
};
export const apiSaveResetParticipantPIP = async (
  participantId,
  pipId,
  data
) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}/save-reset`,
    method: 'POST',
    data,
  });
};
export const apiCancelResetParticipantPIP = async (participantId, pipId) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}/cancel-reset`,
    method: 'POST',
    data: {},
  });
};
export const apiGetParticipantPIPTrial = async (
  participantId,
  pipId,
  params = {}
) => {
  return Request.call({
    url: `/participants/${participantId}/pips/${pipId}/trials`,
    method: 'GET',
    params,
  });
};
