import get from 'lodash/get';
import * as printJS from 'print-js';
import {
  createParticipant,
  deleteParticipant,
  getParticipants,
  updateParticipant,
  getParticipantById,
  updateAvatar,
  deleteCsr,
  addManualCSRToParticipant,
  getPendingAuthCSRForParticipant,
  assignParticipantLocation,
  apiRestoreParticipant,
  apiPrintParticipant,
  apiCreateParticipantPIP,
  apiGetParticipantPIPs,
  apiGetParticipantPIP,
  apiUpdateParticipantPIP,
  apiArchiveParticipantPIP,
  apiActivateParticipantPIP,
  apiGetParticipantPIPRunning,
  apiStartParticipantPIPTrial,
  apiStopParticipantPIPTrial,
  apiCancelParticipantPIPTrial,
  apiGetCurrentUserPIPs,
  apiTogglePIPRunTrial,
  apiSelectParticipantPIPs,
} from 'api/participants';
import Types from '../types/participants';
import NotificationHandler from 'components/Notification/NotificationHandler';

export const syncSetParticipantActivePIPs = (pipId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_SET_PARTICIPANT_ACTIVE_PIPS,
      payload: { pipId, data },
    });
  };
};
export const syncSetReloadRunTrialParticipant = participantId => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_SET_RELOAD_RUN_TRIAL_PARTICIPANT,
      payload: { participantId },
    });
  };
};

export const createManualEntry = (pariticpant, csr, reqData) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_MANUAL_CSR_FOR_PARTICIPANT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await addManualCSRToParticipant(pariticpant, csr, reqData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.CREATE_MANUAL_CSR_FOR_PARTICIPANT_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.CREATE_MANUAL_CSR_FOR_PARTICIPANT_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_MANUAL_CSR_FOR_PARTICIPANT_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchParticipants = (
  q = '',
  page = 1,
  program = 0,
  archived,
  otherFilters = {}
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTICIPANTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getParticipants(
        q,
        page,
        program,
        archived,
        otherFilters
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PARTICIPANTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTICIPANTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTICIPANTS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreParticipants = (
  q = '',
  page = 1,
  program = 0,
  archived,
  otherFilters = {}
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_MORE_PARTICIPANTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getParticipants(
        q,
        page,
        program,
        archived,
        otherFilters
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_PARTICIPANTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_MORE_PARTICIPANTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_MORE_PARTICIPANTS_FAILURE,
        message: error,
      });
    }
  };
};
const generateFormDataForParticipantUpdate = values => {
  const formData = new FormData();
  const { programs, date, month, year, id, ...restData } = values;
  Object.keys(restData).forEach(k => {
    formData.append(k, restData[k] ? restData[k] : '');
  });
  let assignees = [];
  programs.forEach((p, i) => {
    formData.append(`programs[${i}]`, p.id);
    p.assignees.forEach(a => {
      assignees.push({ id: a.id, program_id: p.id });
    });
  });
  assignees.forEach((u, index) => {
    formData.append(`assignees[${index}][program_id]`, u.program_id);
    formData.append(`assignees[${index}][id]`, u.id);
  });
  formData.append('dob', `${month}/${date}/${year}`);

  return formData;
};
const booleanAttr = ['healthy_connections'];
const generateFormDataForParticipantUpdatePUT = values => {
  const {
    programs,
    birth_date,
    start_date_of_services,
    reviced_date,
    ...restData
  } = values;
  const payload = {};
  Object.keys(restData).forEach(k => {
    payload[k] = restData[k]
      ? booleanAttr.includes(k)
        ? restData[k] === 'yes'
          ? true
          : false
        : restData[k]
      : '';
  });
  if (programs && programs.length > 0) {
    payload.programs = [];
    payload.assignees = [];
    programs.forEach((p, i) => {
      payload.programs[i] = p.id;
      p.assignees.forEach(a => {
        payload.assignees.push({ id: a.id, program_id: p.id });
      });
    });
  }
  if (birth_date) {
    payload.dob = birth_date.format('MM/DD/YYYY');
  }
  if (start_date_of_services) {
    payload.start_date_of_services = start_date_of_services.format(
      'MM/DD/YYYY'
    );
  }
  if (reviced_date) {
    payload.reviced_date = reviced_date.format('MM/DD/YYYY');
  }

  return payload;
};

export const addParticipant = participant => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_PARTICIPANT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const { avatar_thumbnail, avatar, ...rest } = participant;
      const participantFormData = generateFormDataForParticipantUpdate(rest);
      if (avatar) {
        participantFormData.append('avatar', avatar);
      }
      const resp = await createParticipant(participantFormData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.CREATE_PARTICIPANT_SUCCESS,
          data: resp || {},
        });
        dispatch(fetchParticipants());
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.CREATE_PARTICIPANT_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_PARTICIPANT_FAILURE,
        message: error,
      });
    }
  };
};

export const archiveParticipant = id => {
  return async dispatch => {
    dispatch({
      type: Types.ARCHIVE_PARTICIPANT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteParticipant(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ARCHIVE_PARTICIPANT_SUCCESS,
          data: resp || {},
        });
        dispatch(fetchParticipants(''));
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.ARCHIVE_PARTICIPANT_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ARCHIVE_PARTICIPANT_FAILURE,
        message: error,
      });
    }
  };
};

export const updateParticipantById = (id, participant, refetchList = true) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_PARTICIPANT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateParticipant(
        id,
        generateFormDataForParticipantUpdatePUT(participant)
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        if (refetchList) {
          // if update is made from list view then refetching list and not updating reducer data
          dispatch({
            type: Types.UPDATE_PARTICIPANT_SUCCESS,
          });
          // dispatch(fetchParticipants());
        } else {
          // if update is made from view profile then updating reducer data
          dispatch({
            type: Types.UPDATE_PARTICIPANT_PROFILE_SUCCESS,
            data: resp || {},
          });
        }
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.UPDATE_PARTICIPANT_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_PARTICIPANT_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchParticipantById = (id, participant) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTICIPANT_PROFILE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getParticipantById(id, participant);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PARTICIPANT_PROFILE_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTICIPANT_PROFILE_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTICIPANT_PROFILE_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchParticipantPendingAuthCSRs = participant => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTICIPANT_PENDING_AUTH_CSRS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getPendingAuthCSRForParticipant(participant);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PARTICIPANT_PENDING_AUTH_CSRS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTICIPANT_PENDING_AUTH_CSRS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTICIPANT_PENDING_AUTH_CSRS_FAILURE,
        message: error,
      });
    }
  };
};

export const updateProfileAvatar = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_PARTICIPANT_FIELD_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateAvatar(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_PARTICIPANT_FIELD_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.UPDATE_PARTICIPANT_FIELD_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_PARTICIPANT_FIELD_FAILURE,
        message: error,
      });
    }
  };
};

export const archiveCsr = (participant, csr) => {
  return async dispatch => {
    dispatch({
      type: Types.ARCHIVE_CSR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteCsr(participant, csr);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ARCHIVE_CSR_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.ARCHIVE_CSR_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ARCHIVE_CSR_FAILURE,
        message: error,
      });
    }
  };
};

export const assignParticipantToLocation = (participant, location) => {
  return async dispatch => {
    dispatch({
      type: Types.ASSIGN_PARTICIPANT_LOCATION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await assignParticipantLocation(participant, location);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ASSIGN_PARTICIPANT_LOCATION_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.ASSIGN_PARTICIPANT_LOCATION_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ASSIGN_PARTICIPANT_LOCATION_FAILURE,
        message: error,
      });
    }
  };
};

export const restoreParticipant = (participantId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.RESTORE_PARTICIPANT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiRestoreParticipant(participantId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.RESTORE_PARTICIPANT_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.showSuccess({
          description: 'Participant was restored.',
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.RESTORE_PARTICIPANT_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.RESTORE_PARTICIPANT_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};

export const printParticipant = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.PRINT_PARTICIPANT_REQUEST,
    });
    try {
      const resp = await apiPrintParticipant(id);
      const status = get(resp, 'status');
      const resData = get(resp, 'data') || '';
      if (status) {
        printJS({
          printable: `${resData}`,
          type: 'pdf',
          base64: true,
        });
        dispatch({
          type: Types.PRINT_PARTICIPANT_SUCCESS,
        });
        if (cb) cb(true);
      } else {
        dispatch({
          type: Types.PRINT_PARTICIPANT_ERROR,
        });
        NotificationHandler.showError({
          description: 'Something went wrong! Please try again.',
        });
        if (cb) cb(false);
      }
    } catch (error) {
      dispatch({
        type: Types.PRINT_PARTICIPANT_ERROR,
      });
      NotificationHandler.showError({
        description: 'Something went wrong! Please try again.',
      });
      if (cb) cb(false);
    }
  };
};

export const createParticipantPIP = (participantId, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_PARTICIPANT_PIP_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiCreateParticipantPIP(participantId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.CREATE_PARTICIPANT_PIP_SUCCESS,
          data: resp || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.CREATE_PARTICIPANT_PIP_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_PARTICIPANT_PIP_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const getParticipantDraftPIPs = (participantId, params, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PARTICIPANT_DRAFT_PIPS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetParticipantPIPs(participantId, {
        ...(params || {}),
        status: 'Draft',
      });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_PARTICIPANT_DRAFT_PIPS_SUCCESS,
          data: resp.data || [],
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_PARTICIPANT_DRAFT_PIPS_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PARTICIPANT_DRAFT_PIPS_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const getParticipantActivePIPs = (participantId, params, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PARTICIPANT_ACTIVE_PIPS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetParticipantPIPs(participantId, {
        ...(params || {}),
        status: 'Active',
      });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_PARTICIPANT_ACTIVE_PIPS_SUCCESS,
          data: resp.data || [],
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_PARTICIPANT_ACTIVE_PIPS_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PARTICIPANT_ACTIVE_PIPS_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const getParticipantArchivedPIPs = (participantId, params, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PARTICIPANT_ARCHIVED_PIPS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetParticipantPIPs(participantId, {
        ...(params || {}),
        status: 'Archived',
      });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_PARTICIPANT_ARCHIVED_PIPS_SUCCESS,
          data: resp.data || [],
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_PARTICIPANT_ARCHIVED_PIPS_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PARTICIPANT_ARCHIVED_PIPS_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const getParticipantPIP = (participantId, pipId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PARTICIPANT_PIP_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetParticipantPIP(participantId, pipId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_PARTICIPANT_PIP_SUCCESS,
          data: resp.data || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_PARTICIPANT_PIP_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PARTICIPANT_PIP_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const updateParticipantPIP = (participantId, pipId, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_PARTICIPANT_PIP_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiUpdateParticipantPIP(participantId, pipId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_PARTICIPANT_PIP_SUCCESS,
          data: resp.data || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.UPDATE_PARTICIPANT_PIP_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_PARTICIPANT_PIP_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const archiveParticipantPIP = (participantId, pipId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.ARCHIVE_PARTICIPANT_PIP_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiArchiveParticipantPIP(participantId, pipId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ARCHIVE_PARTICIPANT_PIP_SUCCESS,
          data: resp.data || {},
        });
        NotificationHandler.showSuccess({
          description: 'The PIP was archived successfully',
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.ARCHIVE_PARTICIPANT_PIP_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.ARCHIVE_PARTICIPANT_PIP_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const activateParticipantPIP = (participantId, pipId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.ACTIVATE_PARTICIPANT_PIP_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiActivateParticipantPIP(participantId, pipId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ACTIVATE_PARTICIPANT_PIP_SUCCESS,
          data: resp.data || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.ACTIVATE_PARTICIPANT_PIP_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.ACTIVATE_PARTICIPANT_PIP_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const getParticipantPIPRunning = (participantId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PARTICIPANT_PIP_RUNNING_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetParticipantPIPRunning(participantId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_PARTICIPANT_PIP_RUNNING_SUCCESS,
          data: resp.data || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_PARTICIPANT_PIP_RUNNING_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PARTICIPANT_PIP_RUNNING_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const startParticipantPIPTrial = (participantId, pipId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.START_PARTICIPANT_PIP_TRIAL_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiStartParticipantPIPTrial(participantId, pipId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.START_PARTICIPANT_PIP_TRIAL_SUCCESS,
          data: resp.data || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.START_PARTICIPANT_PIP_TRIAL_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.START_PARTICIPANT_PIP_TRIAL_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const stopParticipantPIPTrial = (participantId, pipId, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.STOP_PARTICIPANT_PIP_TRIAL_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiStopParticipantPIPTrial(participantId, pipId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.STOP_PARTICIPANT_PIP_TRIAL_SUCCESS,
          data: resp.data || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.STOP_PARTICIPANT_PIP_TRIAL_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.STOP_PARTICIPANT_PIP_TRIAL_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};
export const cancelParticipantPIPTrial = (participantId, pipId, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.CANCEL_PARTICIPANT_PIP_TRIAL_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiCancelParticipantPIPTrial(
        participantId,
        pipId,
        data
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.CANCEL_PARTICIPANT_PIP_TRIAL_SUCCESS,
          data: resp.data || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.CANCEL_PARTICIPANT_PIP_TRIAL_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.CANCEL_PARTICIPANT_PIP_TRIAL_ERROR,
        message: error,
      });
      if (cb) cb(false, error);
    }
  };
};

export const getCurrentUserPIPs = cb => {
  return async dispatch => {
    dispatch({
      type: Types.GET_CURRENT_USER_PIP_REQUEST,
    });
    const resp = await apiGetCurrentUserPIPs();
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      const tData = resp.data || {};
      dispatch({
        type: Types.GET_CURRENT_USER_PIP_SUCCESS,
        payload: tData,
      });
      if (cb) cb(resp);
    } else {
      dispatch({
        type: Types.GET_CURRENT_USER_PIP_ERROR,
        payload: message,
      });
      if (cb) cb(false);
    }
  };
};
export const selectParticipantPIPs = (data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.SELECT_PARTICIPANT_PIPS_REQUEST,
    });
    const resp = await apiSelectParticipantPIPs(data);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    if (status) {
      const tData = resp.data || {};
      dispatch({
        type: Types.SELECT_PARTICIPANT_PIPS_SUCCESS,
        payload: tData,
      });
      if (cb) cb(resp);
    } else {
      dispatch({
        type: Types.SELECT_PARTICIPANT_PIPS_ERROR,
        payload: message,
      });
      if (cb) cb(false);
    }
  };
};
export const toggleParticipantPIPTrial = (participantId, pipId, isON, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.TOGGLE_PARTICIPANT_PIP_TRIAL_REQUEST,
    });
    dispatch(
      syncSetParticipantActivePIPs(pipId, {
        can_run_trial: isON ? 1 : 0,
      })
    );
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiTogglePIPRunTrial(participantId, pipId, isON);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.TOGGLE_PARTICIPANT_PIP_TRIAL_SUCCESS,
          data: resp.data || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.TOGGLE_PARTICIPANT_PIP_TRIAL_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        dispatch(
          syncSetParticipantActivePIPs(pipId, {
            can_run_trial: isON ? 0 : 1,
          })
        );
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.TOGGLE_PARTICIPANT_PIP_TRIAL_ERROR,
        message: error,
      });
      syncSetParticipantActivePIPs(pipId, {
        can_run_trial: isON ? 0 : 1,
      });
      if (cb) cb(false, error);
    }
  };
};
