import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { WarningFilled, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getCurrentStartPeriod, getEndPeriod } from 'util/period';
import { DATE_BE_FORMAT_1 } from 'helpers/constants';
import classes from './Payroll.module.scss';

export const getFilterParams = (
  filterObj,
  page = 1,
  userTimeZone,
  size = 10,
  keepEmpty = false
) => {
  const { filterBy, filters = [] } = filterObj || {};
  const status = find(filters, { key: 'status' }) || {};
  const shift = find(filters, { key: 'shift' }) || {};
  const start = find(filters, { key: 'start' }) || {};
  const end = find(filters, { key: 'end' }) || {};
  const location = find(filters, { key: 'location' }) || {};
  const employeeName = find(filters, { key: 'employeeName' }) || {};
  const returnObj = {
    page,
    limit: size,
  };
  if (filterBy && filterBy !== 'All Payroll Entries') {
    returnObj.payroll_filter = filterBy;
  }
  if (status.val && status.val.length > 0) {
    returnObj.status = status.val;
  } else if (keepEmpty) {
    returnObj.status = [];
  }
  if (shift.val && shift.val.length > 0) {
    returnObj.shift_type = shift.val;
  } else if (keepEmpty) {
    returnObj.shift_type = [];
  }
  if (location.val && location.val.length > 0) {
    returnObj.location_id = location.val;
  } else if (keepEmpty) {
    returnObj.location_id = [];
  }
  if (employeeName.val && employeeName.val.length > 0) {
    returnObj.user_id = employeeName.val;
  } else if (keepEmpty) {
    returnObj.user_id = [];
  }
  if (start.val && start.val.length > 0) {
    returnObj.date_from = moment(start.val[0])
      .tz(userTimeZone)
      .format(DATE_BE_FORMAT_1);
  }
  if (end.val && end.val.length > 0) {
    returnObj.date_to = moment(end.val[0])
      .tz(userTimeZone)
      .format(DATE_BE_FORMAT_1);
  }
  return returnObj;
};

export const handleOpenWarningModal = modal => (
  content,
  isApprove,
  onOk,
  onCancel = () => {},
  title,
  additionalSettings
) => {
  const addSettings = additionalSettings || {};
  modal.confirm({
    title: title || `${isApprove ? 'Approve' : 'Delete'} Payroll Records`,
    content,
    centered: true,
    cancelText: addSettings.cancelText || 'Cancel',
    okText: addSettings.okText || (isApprove ? 'Approve All' : 'Delete'),
    icon: <WarningFilled />,
    className: cn(
      classes.confirmModal,
      {
        [classes.isDelete]: !isApprove,
        [classes.isInfo]: addSettings.isInfo,
      },
      addSettings.className
    ),
    onOk,
    onCancel,
    closable: true,
    closeIcon: <CloseOutlined />,
    okButtonProps: {
      size: 'large',
    },
    cancelButtonProps: {
      size: 'large',
    },
  });
};
export const getTimeValueBE = (val, userTimeZone) => {
  if (!val) return '';
  return moment(val)
    .tz(userTimeZone)
    .startOf('minute')
    .format('HH:mm:ss');
};
export const getPayrollBR = (values, userTimeZone, isNightAssist) => {
  const firstTimeObj = get(values, 'timesArray[0]') || {};
  if (isEmpty(firstTimeObj)) return {};
  const { startDate, endDate } = firstTimeObj;
  const returnObj = {
    checked_in_at: `${
      startDate
        ? moment(startDate)
            .tz(userTimeZone)
            .format(DATE_BE_FORMAT_1)
        : ''
    } ${getTimeValueBE(firstTimeObj.checked_in_at, userTimeZone)}`,
    checked_out_at: `${
      endDate
        ? moment(endDate)
            .tz(userTimeZone)
            .format(DATE_BE_FORMAT_1)
        : ''
    } ${getTimeValueBE(firstTimeObj.checked_out_at, userTimeZone)}`,
    location_id: values.location,
  };
  if (!isNightAssist) {
    returnObj.program_id = values.program;
    returnObj.shift_type = values.shiftType;
  } else {
    returnObj.participant_id = values.participant;
    returnObj.description = values.description;
  }
  return returnObj;
};
export const getDefaultFilters = userTimeZone => {
  const currentStart = getCurrentStartPeriod(userTimeZone);
  const currentEnd = getEndPeriod(currentStart);
  return [
    { key: 'start', val: [currentStart] },
    { key: 'end', val: [currentEnd] },
  ];
};
export const getSubmitDefaultFilter = userTimeZone => {
  const currentStart = getCurrentStartPeriod(userTimeZone);
  const currentEnd = getEndPeriod(currentStart);
  return {
    start: currentStart,
    end: currentEnd,
  };
};
export const getDateFilterParams = (dateFilter, userTimeZone) => {
  const returnObj = {};
  if (dateFilter?.start) {
    returnObj.date_from = moment(dateFilter.start)
      .tz(userTimeZone)
      .format(DATE_BE_FORMAT_1);
  }
  if (dateFilter?.end) {
    returnObj.date_to = moment(dateFilter.end)
      .tz(userTimeZone)
      .format(DATE_BE_FORMAT_1);
  }
  return returnObj;
};
