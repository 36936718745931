import Types from '../types/trialsApprovals';

const initialState = {
  allTrialsApprovals: {
    loading: false,
    data: [],
    error: '',
  },
  myTrialsApprovals: {
    loading: false,
    data: [],
    error: '',
  },
  allTrialsSubmittedApprovals: {
    loading: false,
    data: [],
    error: '',
  },
  myTrialsSubmittedApprovals: {
    loading: false,
    data: [],
    error: '',
  },
  participantWaitingApprovals: {
    loading: false,
    data: [],
    error: '',
  },
  trialsSubmitted: {
    loading: false,
    data: [],
    error: '',
  },
  approveTrials: {
    loading: false,
    data: {},
    error: '',
  },
  submitTrials: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function TrialsApprovals(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ALL_TRIALS_APPROVALS_REQUEST:
      return {
        ...state,
        allTrialsApprovals: {
          ...state.allTrialsApprovals,
          loading: true,
        },
      };
    case Types.GET_ALL_TRIALS_APPROVALS_SUCCESS:
      return {
        ...state,
        allTrialsApprovals: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_ALL_TRIALS_APPROVALS_ERROR:
      return {
        ...state,
        allTrialsApprovals: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_MY_TRIALS_APPROVALS_REQUEST:
      return {
        ...state,
        myTrialsApprovals: {
          ...state.myTrialsApprovals,
          loading: true,
        },
      };
    case Types.GET_MY_TRIALS_APPROVALS_SUCCESS:
      return {
        ...state,
        myTrialsApprovals: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_MY_TRIALS_APPROVALS_ERROR:
      return {
        ...state,
        myTrialsApprovals: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_ALL_TRIALS_SUBMITTED_APPROVALS_REQUEST:
      return {
        ...state,
        allTrialsSubmittedApprovals: {
          ...state.allTrialsSubmittedApprovals,
          loading: true,
        },
      };
    case Types.GET_ALL_TRIALS_SUBMITTED_APPROVALS_SUCCESS:
      return {
        ...state,
        allTrialsSubmittedApprovals: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_ALL_TRIALS_SUBMITTED_APPROVALS_ERROR:
      return {
        ...state,
        allTrialsSubmittedApprovals: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_MY_TRIALS_SUBMITTED_APPROVALS_REQUEST:
      return {
        ...state,
        myTrialsSubmittedApprovals: {
          ...state.myTrialsSubmittedApprovals,
          loading: true,
        },
      };
    case Types.GET_MY_TRIALS_SUBMITTED_APPROVALS_SUCCESS:
      return {
        ...state,
        myTrialsSubmittedApprovals: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_MY_TRIALS_SUBMITTED_APPROVALS_ERROR:
      return {
        ...state,
        myTrialsSubmittedApprovals: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.VIEW_TRIALS_SUBMITTED_REQUEST:
      return {
        ...state,
        trialsSubmitted: {
          ...state.trialsSubmitted,
          loading: true,
        },
      };
    case Types.VIEW_TRIALS_SUBMITTED_SUCCESS:
      return {
        ...state,
        trialsSubmitted: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.VIEW_TRIALS_SUBMITTED_ERROR:
      return {
        ...state,
        trialsSubmitted: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.APPROVE_TRIALS_REQUEST:
      return {
        ...state,
        approveTrials: {
          ...state.approveTrials,
          loading: true,
        },
      };
    case Types.APPROVE_TRIALS_SUCCESS:
      return {
        ...state,
        approveTrials: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.APPROVE_TRIALS_ERROR:
      return {
        ...state,
        approveTrials: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.SUBMIT_TRIALS_REQUEST:
      return {
        ...state,
        submitTrials: {
          ...state.submitTrials,
          loading: true,
        },
      };
    case Types.SUBMIT_TRIALS_SUCCESS:
      return {
        ...state,
        submitTrials: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.SUBMIT_TRIALS_ERROR:
      return {
        ...state,
        submitTrials: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    default:
      return state;
  }
}
