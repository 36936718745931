import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import Types from '../types/participants';

const initialState = {
  reloadRunTrialParticipantId: null,
  participantsList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  lazyLoad: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  addParticipant: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  archiveParticipant: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  updateParticipant: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  restoreParticipant: {
    loading: false,
    status: 0,
    error: '',
  },
  createParticipantPIP: {
    loading: false,
    data: {},
    error: '',
  },
  participantDraftPIPs: {
    loading: false,
    data: [],
    error: '',
  },
  participantActivePIPs: {
    loading: false,
    data: [],
    error: '',
  },
  participantArchivedPIPs: {
    loading: false,
    data: [],
    error: '',
  },
  participantPIP: {
    loading: false,
    data: {},
    error: '',
  },
  participantPIPRunning: {
    loading: false,
    data: {},
    error: '',
  },
  startParticipantPIPTrial: {
    loading: false,
    data: {},
    error: '',
  },
  stopParticipantPIPTrial: {
    loading: false,
    data: {},
    error: '',
  },
  cancelParticipantPIPTrial: {
    loading: false,
    data: {},
    error: '',
  },
  currentUserPIPs: {
    loading: false,
    data: {},
    error: '',
  },
  selectParticipantPIPs: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Participants(state = initialState, action) {
  switch (action.type) {
    case Types.SYNC_SET_RELOAD_RUN_TRIAL_PARTICIPANT:
      return {
        ...state,
        reloadRunTrialParticipantId: action.payload.participantId,
      };
    case Types.FETCH_PARTICIPANTS_INPROGRESS:
      return {
        ...state,
        participantsList: {
          ...state.participantsList,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participantsList: {
          ...state.participantsList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PARTICIPANTS_FAILURE:
      return {
        ...state,
        participantsList: {
          ...state.participantsList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_PARTICIPANTS_INPROGRESS:
      return {
        ...state,
        lazyLoad: {
          ...state.lazyLoad,
          isInProgress: true,
        },
      };
    case Types.FETCH_MORE_PARTICIPANTS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data: newData, ...rest } = action.data;
      return {
        ...state,
        lazyLoad: {
          ...state.lazyLoad,
          isInProgress: false,
          status: 1,
        },
        participantsList: {
          ...state.participantsList,
          data: {
            data: uniqBy(
              [...get(state, 'participantsList.data.data', []), ...newData],
              'id'
            ),
            ...rest,
          },
        },
      };
    case Types.FETCH_MORE_PARTICIPANTS_FAILURE:
      return {
        ...state,
        lazyLoad: {
          ...state.lazyLoad,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_PARTICIPANT_INPROGRESS:
      return {
        ...state,
        addParticipant: {
          ...state.addParticipant,
          isInProgress: true,
        },
      };
    case Types.CREATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        addParticipant: {
          ...state.addParticipant,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        addParticipant: {
          ...state.addParticipant,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.ARCHIVE_PARTICIPANT_INPROGRESS:
      return {
        ...state,
        archiveParticipant: {
          ...state.archiveParticipant,
          isInProgress: true,
        },
      };
    case Types.ARCHIVE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        archiveParticipant: {
          ...state.archiveParticipant,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.ARCHIVE_PARTICIPANT_FAILURE:
      return {
        ...state,
        archiveParticipant: {
          ...state.archiveParticipant,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_PARTICIPANT_INPROGRESS:
      return {
        ...state,
        updateParticipant: {
          ...state.updateParticipant,
          isInProgress: true,
        },
      };
    case Types.UPDATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        updateParticipant: {
          ...state.updateParticipant,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        updateParticipant: {
          ...state.updateParticipant,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.RESTORE_PARTICIPANT_INPROGRESS:
      return {
        ...state,
        restoreParticipant: {
          ...state.restoreParticipant,
          loading: true,
        },
      };
    case Types.RESTORE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        restoreParticipant: {
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.RESTORE_PARTICIPANT_ERROR:
      return {
        ...state,
        restoreParticipant: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.CREATE_PARTICIPANT_PIP_REQUEST:
      return {
        ...state,
        createParticipantPIP: {
          ...state.createParticipantPIP,
          loading: true,
        },
      };
    case Types.CREATE_PARTICIPANT_PIP_SUCCESS:
      return {
        ...state,
        createParticipantPIP: {
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.CREATE_PARTICIPANT_PIP_ERROR:
      return {
        ...state,
        createParticipantPIP: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_PARTICIPANT_DRAFT_PIPS_REQUEST:
      return {
        ...state,
        participantDraftPIPs: {
          ...state.participantDraftPIPs,
          loading: true,
        },
      };
    case Types.GET_PARTICIPANT_DRAFT_PIPS_SUCCESS:
      return {
        ...state,
        participantDraftPIPs: {
          data: action.data,
          error: '',
          loading: false,
        },
      };
    case Types.GET_PARTICIPANT_DRAFT_PIPS_ERROR:
      return {
        ...state,
        participantDraftPIPs: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_PARTICIPANT_ACTIVE_PIPS_REQUEST:
      return {
        ...state,
        participantActivePIPs: {
          ...state.participantActivePIPs,
          loading: true,
        },
      };
    case Types.GET_PARTICIPANT_ACTIVE_PIPS_SUCCESS:
      return {
        ...state,
        participantActivePIPs: {
          data: action.data,
          error: '',
          loading: false,
        },
      };
    case Types.SYNC_SET_PARTICIPANT_ACTIVE_PIPS:
      return {
        ...state,
        participantActivePIPs: {
          ...state.participantActivePIPs,
          data: state.participantActivePIPs.data.map(obj => {
            if (obj.id === action.payload.pipId) {
              return {
                ...obj,
                ...action.payload.data,
              };
            }
            return obj;
          }),
        },
      };
    case Types.GET_PARTICIPANT_ACTIVE_PIPS_ERROR:
      return {
        ...state,
        participantActivePIPs: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_PARTICIPANT_ARCHIVED_PIPS_REQUEST:
      return {
        ...state,
        participantArchivedPIPs: {
          ...state.participantArchivedPIPs,
          loading: true,
        },
      };
    case Types.GET_PARTICIPANT_ARCHIVED_PIPS_SUCCESS:
      return {
        ...state,
        participantArchivedPIPs: {
          data: action.data,
          error: '',
          loading: false,
        },
      };
    case Types.GET_PARTICIPANT_ARCHIVED_PIPS_ERROR:
      return {
        ...state,
        participantArchivedPIPs: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_PARTICIPANT_PIP_REQUEST:
      return {
        ...state,
        participantPIP: {
          loading: true,
          error: '',
          data: {},
        },
      };
    case Types.GET_PARTICIPANT_PIP_SUCCESS:
      return {
        ...state,
        participantPIP: {
          data: action.data,
          error: '',
          loading: false,
        },
      };
    case Types.GET_PARTICIPANT_PIP_ERROR:
      return {
        ...state,
        participantPIP: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_PARTICIPANT_PIP_RUNNING_REQUEST:
      return {
        ...state,
        participantPIPRunning: {
          ...state.participantPIPRunning,
          loading: true,
        },
      };
    case Types.GET_PARTICIPANT_PIP_RUNNING_SUCCESS:
      return {
        ...state,
        participantPIPRunning: {
          data: action.data,
          error: '',
          loading: false,
        },
      };
    case Types.GET_PARTICIPANT_PIP_RUNNING_ERROR:
      return {
        ...state,
        participantPIPRunning: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.START_PARTICIPANT_PIP_TRIAL_REQUEST:
      return {
        ...state,
        startParticipantPIPTrial: {
          ...state.startParticipantPIPTrial,
          loading: true,
        },
      };
    case Types.START_PARTICIPANT_PIP_TRIAL_SUCCESS:
      return {
        ...state,
        startParticipantPIPTrial: {
          data: action.data,
          error: '',
          loading: false,
        },
      };
    case Types.START_PARTICIPANT_PIP_TRIAL_ERROR:
      return {
        ...state,
        startParticipantPIPTrial: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.STOP_PARTICIPANT_PIP_TRIAL_REQUEST:
      return {
        ...state,
        stopParticipantPIPTrial: {
          ...state.stopParticipantPIPTrial,
          loading: true,
        },
      };
    case Types.STOP_PARTICIPANT_PIP_TRIAL_SUCCESS:
      return {
        ...state,
        stopParticipantPIPTrial: {
          data: action.data,
          error: '',
          loading: false,
        },
      };
    case Types.STOP_PARTICIPANT_PIP_TRIAL_ERROR:
      return {
        ...state,
        stopParticipantPIPTrial: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.CANCEL_PARTICIPANT_PIP_TRIAL_REQUEST:
      return {
        ...state,
        cancelParticipantPIPTrial: {
          ...state.cancelParticipantPIPTrial,
          loading: true,
        },
      };
    case Types.CANCEL_PARTICIPANT_PIP_TRIAL_SUCCESS:
      return {
        ...state,
        cancelParticipantPIPTrial: {
          data: action.data,
          error: '',
          loading: false,
        },
      };
    case Types.CANCEL_PARTICIPANT_PIP_TRIAL_ERROR:
      return {
        ...state,
        cancelParticipantPIPTrial: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_CURRENT_USER_PIP_REQUEST:
      return {
        ...state,
        currentUserPIPs: {
          ...state.currentUserPIPs,
          loading: true,
        },
      };
    case Types.GET_CURRENT_USER_PIP_SUCCESS:
      return {
        ...state,
        currentUserPIPs: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_CURRENT_USER_PIP_ERROR:
      return {
        ...state,
        currentUserPIPs: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.SELECT_PARTICIPANT_PIPS_REQUEST:
      return {
        ...state,
        selectParticipantPIPs: {
          ...state.selectParticipantPIPs,
          loading: true,
        },
      };
    case Types.SELECT_PARTICIPANT_PIPS_SUCCESS:
      return {
        ...state,
        selectParticipantPIPs: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.SELECT_PARTICIPANT_PIPS_ERROR:
      return {
        ...state,
        selectParticipantPIPs: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
